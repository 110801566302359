import { PageProps } from 'gatsby';
import * as React from 'react';

import { Layout, Grid } from '../components/Layout';
import { Onboarding } from '../components/Onboarding';
import { ContentfulPage } from '../models/ContentfulTypes';
import { getUrlWithLanguage } from '../utils';

interface Props extends PageProps {
  pageContext: {
    page: ContentfulPage;
    language: string;
    contentfulId: string;
  };
}

const Welcome = ({ pageContext }: Props) => {
  const { page, language, contentfulId } = pageContext;
  const nextPage = getUrlWithLanguage(language, '/install');
  return (
    <Layout contentfulId={contentfulId} title={page.title}>
      <Grid>
        <Onboarding
          url={page.photo?.file?.url}
          title={page.title}
          message={page.heading?.heading || ''}
          buttonText={page.nextButtonText}
          nextPage={nextPage}
        />
      </Grid>
    </Layout>
  );
};

export default Welcome;
